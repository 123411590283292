<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="view_employee" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" :style="`direction:`+lang.ldir" right :title="lang.view_employee" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.view_employee}}</span>
                    </div>
                    <div @click="hide" id="HideMe" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                 <v-divider></v-divider>
                 <div class="px-3 py-2" style="direction:rtl">
                    <v-row style="text-align:center;direction:rtl" class="m-2">
                        <v-col cols="12" style="text-align:end">
                            <b-button class="btn-sm btn-danger pa-1 m-2" style="min-width:150px;" v-b-toggle.emp_deducts>خصم من الراتب</b-button>
                            <b-button class="btn-sm btn-warning pa-1 m-2" style="min-width:150px;background:#bf7b00;color:#FFF;" v-b-toggle.advanced_paid>اضافة سلفة</b-button>
                            <b-button class="btn-sm btn-success pa-1 m-2" style="min-width:150px;" v-b-toggle.emp_reward>اضافة مكافأة</b-button>
                            <b-button class="btn-sm pa-1 m-2" style="min-width:150px;background:darkblue;color:#FFF;" v-b-toggle.emp_custody>اضافة عهدة</b-button>
                            <b-button class="btn-sm pa-1 m-2" style="min-width:150px;background:#115860;color:#FFF;" @click="$refs.fixsalary.doitall()" v-if="salaryregisterd == 0" v-b-toggle.fix_salary>اصدار الراتب</b-button>
                        </v-col>
                    </v-row>
                    <v-row style="text-align:center;direction:rtl" class="m-2">
                        <v-col cols="12" md="4" sm="6">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="width:45%;">تاريخ بدء العمل</th>
                                        <td class="fborder">{{ startdate.substring(0,10) }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                            
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="width:45%;">مكافئة نهاية الخدمة</th>
                                        <td class="fborder">{{endofservices}} ريال</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                    </v-row>
                    <v-row style="text-align:center;direction:rtl" class="m-2">
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="2">
                                    <label>اختر الشهر</label>
                                    <b-input-group>
                                    <b-form-select
                                        v-model="month"
                                        :options="months"
                                        class="selborder"
                                        style="width:200px;"
                                        @change="getEmployees()"
                                    ></b-form-select>
                                        <b-input-group-addon style="margin-right:-35px;margin-top:5px;">
                                            <i class="fas fa-arrow-down"></i>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </v-col>
                                <v-col cols="2">
                                    <label>السنة</label>
                                    <b-form-input class="inborder"
                                    label="السنة"
                                    v-model="year"
                                    type="number"
                                    style="width:200px;"
                                    @change="getEmployees()"
                                    >{{ year }}</b-form-input>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="salaryregisterd == 1">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th>الراتب المصدر</th>
                                    </tr>
                                    <tr>
                                        <th>اجمالي الراتب</th>
                                        <th>الخصومات</th>
                                        <th>السلف</th>
                                        <th>المكافآت</th>
                                        <th v-if="$store.state.licenseType.cars">نسبة كروت الصيانة</th>
                                        <th>نسبة المبيعات</th>
                                        <th>صافي الراتب</th>
                                        <th>ملاحظات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{rsalary[0].stotal}}</td>
                                        <td>{{rsalary[0].discounts}}</td>
                                        <td>{{rsalary[0].padvanced}}</td>
                                        <td>{{rsalary[0].bonuce}}</td>
                                        <td v-if="$store.state.licenseType.cars">{{rsalary[0].ptotal}}</td>
                                        <td>{{rsalary[0].ipercentage}}</td>
                                        <td>{{rsalary[0].paidsalary}}</td>
                                        <td>{{rsalary[0].notes}}</td>
                                    </tr>
                                </tbody>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="4">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="width:45%;">اسم الموظف</th>
                                        <td class="fborder">{{ employees.employee_name }}</td>
                                    </tr>
                                    <tr>
                                        <th>رقم الاتصال</th>
                                        <td class="fborder">{{ employees.mobile }}</td>
                                    </tr>
                                    <tr>
                                        <th>الراتب الاساسي</th>
                                        <td class="fborder">{{ employees.salary }}</td>
                                    </tr>
                                    <tr>
                                        <th>بدل السكن</th>
                                        <td class="fborder">{{ employees.home_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th>بدل النقل</th>
                                        <td class="fborder">{{ employees.tarns_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th>بدل الطعام</th>
                                        <td class="fborder">{{ employees.food_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th>بدلات اخرى</th>
                                        <td class="fborder">{{ employees.others_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th>اجمالي الراتب</th>
                                        <td class="fborder">{{ employees.stotal }}</td>
                                    </tr>
                                    <tr>
                                        <th>الخصومات</th>
                                        <td class="fborder">{{discounts}}</td>
                                    </tr>
                                    <tr>
                                        <th>السلف</th>
                                        <td class="fborder">{{padvanced}}</td>
                                    </tr>
                                    <tr>
                                        <th>المكافآت</th>
                                        <td class="fborder">{{bonuce}}</td>
                                    </tr>
                                    <tr v-if="$store.state.licenseType.cars">
                                        <th>نسبة كروت الصيانة</th>
                                        <td class="fborder">{{ptotal}}</td>
                                    </tr>
                                    <tr>
                                        <th>نسبة الفواتير</th>
                                        <td class="fborder">{{ipercentage}}</td>
                                    </tr>
                                    <tr>
                                        <th>صافي الراتب</th>
                                        <td class="fborder">{{ netsalary }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="8">
                            <b-table-simple v-if="$store.state.licenseType.cars">
                                <thead>
                                    <tr>
                                        <th colspan="8">بطاقات الصيانة</th>
                                    </tr>
                                    <tr>
                                        <th>رقم البطاقة</th>
                                        <th>تاريخ البطاقة</th>
                                        <th>اسم العميل</th>
                                        <th>رقم اللوحة</th>
                                        <th>الخدمات</th>
                                        <th>المصروفات</th>
                                        <th>المتبقي</th>
                                        <th>نسبة العمل</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in cardsrows" :key="index">
                                        <td>{{item.cardid}}</td>
                                        <td>{{item.date_in.substring(0,10)}}</td>
                                        <td>{{item.customer_name}}</td>
                                        <td>{{item.plate_number}}</td>
                                        <td>{{item.service}}</td>
                                        <td>{{item.expense}}</td>
                                        <td>{{item.setotal}}</td>
                                        <td>{{item.percentage}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>المجموع</th>
                                        <th>{{stotal}}</th>
                                        <th>{{extotal}}</th>
                                        <th>{{csubtotal}}</th>
                                        <th>{{ptotal}}</th>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="6">مبيعات الفواتير</th>
                                    </tr>
                                    <tr>
                                        <th>رقم الفاتورة</th>
                                        <th>تاريخ الفاتورة</th>
                                        <!-- <th>اسم العميل</th> -->
                                        <th>الاجمالي بدون ضريبة</th>
                                        <th>مصروفات</th>
                                        <th>المتبقي</th>
                                        <th>النسبة</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in invoicerows" :key="index">
                                        <td>{{item.invoice_number}}</td>
                                        <td>{{item.inv_date.substring(0,10)}}</td>
                                        <td>{{item.total}}</td>
                                        <td>{{item.expense}}</td>
                                        <td>{{item.remain}}</td>
                                        <td>{{item.percentage}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th>المجموع</th>
                                        <th>{{itotal}}</th>
                                        <th>{{iexpense}}</th>
                                        <th>{{iremain}}</th>
                                        <th>{{ipercentage}}</th>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="6">السلف</th>
                                    </tr>
                                    <tr>
                                        <th>تاريخ السلفة</th>
                                        <th>قيمة السلفة</th>
                                        <th>عدد الاقساط</th>
                                        <th>قيمة القسط</th>
                                        <th>المرتجع</th>
                                        <th>المتبقي</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in payadvanced" :key="index">
                                        <td>{{item.date_received.substring(0,10)}}</td>
                                        <td>{{item.amount}}</td>
                                        <td>{{item.installment_number}}</td>
                                        <td v-html="$RoundNum(parseFloat(item.amount) / parseFloat(item.installment_number))"></td>
                                        <td>{{item.paid_amount}}</td>
                                        <td>{{item.rest_amount}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>المجموع</th>
                                        <th>{{installment_total}}</th>
                                        <th>-</th>
                                        <th>{{installment_amount}}</th>
                                        <th>{{installment_paid}}</th>
                                        <th>{{installment_rest}}</th>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="4">المكافآت</th>
                                    </tr>
                                    <tr>
                                        <th>تاريخ المكافئة</th>
                                        <th>قيمة المكافئة</th>
                                        <th>سبب المكافئة</th>
                                        <th>الحالة</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in rewarded" :key="index">
                                        <td>{{item.date_received.substring(0,10)}}</td>
                                        <td>{{item.amount}}</td>
                                        <td>{{item.reward_resone}}</td>
                                        <td v-html="item.status == 1 ? lang.notpaid : (item.status == 2 ? lang.paid : lang.canceled)"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>{{lang.totals}}</td>
                                        <td>{{rewtotals}}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="3">الخصومات</th>
                                    </tr>
                                    <tr>
                                        <th>تاريخ الخصم</th>
                                        <th>قيمة الخصم</th>
                                        <th>سبب الخصم</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in deducts" :key="index">
                                        <td>{{item.date_received.substring(0,10)}}</td>
                                        <td>{{item.amount}}</td>
                                        <td>{{item.reward_resone}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>{{lang.totals}}</td>
                                        <td>{{dedtotals}}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="4">العهد</th>
                                    </tr>
                                    <tr>
                                        <th>تاريخ التسليم</th>
                                        <th>قيمة العهدة</th>
                                        <th>المبلغ المسلم</th>
                                        <th>المبلغ المتبقي</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in custodies" :key="index">
                                        <td>{{item.date_received.substring(0,10)}}</td>
                                        <td>{{item.amount}}</td>
                                        <td>{{item.paid}}</td>
                                        <td>{{item.remaining}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>{{lang.totals}}</td>
                                        <td>{{custotals}}</td>
                                        <td>{{cusptotals}}</td>
                                        <td>{{cusrtotals}}</td>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                        </v-col>
                    </v-row>
                 </div>
            </template>
        </b-sidebar>
        </v-form>
        <advanced-paid />
        <employee-reward />
        <employee-deduct />
        <employee-emp-custody />
        <fix-salary ref="fixsalary" />
        
    </div>
</template>

<script>
import axios from 'axios'
import AdvancedPaid from '@/components/advanced-paid.vue';
import EmployeeReward from '@/components/employee-reward.vue';
import EmployeeDeduct from '@/components/employee-deduct.vue';
import EmployeeEmpCustody from '@/components/employee-emp-custody.vue';
import FixSalary from '@/components/fix-salary.vue';
export default {
    components: {AdvancedPaid,EmployeeReward,EmployeeDeduct,EmployeeEmpCustody,FixSalary },
    data() {
        return {
            empid: 0,
            employee:{},
            employees:{
                startdate: '',
            },
            cardsrows: [],
            invoicerows: [],
            rewarded: [],
            payadvanced: [],
            deducts: [],
            custodies: [],
            endofservice: 0,
            months: [
                {text: "January",value:1},
                {text: "February",value:2},
                {text: "March",value:3},
                {text: "April",value:4},
                {text: "May",value:5},
                {text: "June",value:6},
                {text: "July",value:7},
                {text: "August",value:8},
                {text: "September",value:9},
                {text: "October",value:10},
                {text: "November",value:11},
                {text: "December",value:12},
            ],
            month: '',
            year: 2022,
            rsalary: [],
            salaryregisterd: 0
        }
    },
    methods: {
        getMonth(){
            let tm = new Date();
            this.month = parseFloat(tm.getMonth()) + parseFloat(1);
            this.year = tm.getFullYear()
        },
        getEmployees()
        {
            const post = new FormData();
            post.append("type" , "getAllEmp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.empid);
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // console.log(response.data);
                const res = response.data.results.data;
                this.employees = res[0];
                this.cardsrows = res[0].cards;
                this.invoicerows = res[0].invoices;
                this.payadvanced = res[0].payadvanced;
                this.rewarded = res[0].rewarded;
                this.deducts = res[0].deducts;
                this.custodies = res[0].custodies;
                this.rsalary = res[0].paidsalary;
                this.salaryregisterd = res[0].salaryregisterd;
            })
        }
    },
   
    created() {
        // this.getEmployees();
        this.getMonth();
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        stotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].service);
            }
            return this.$RoundNum(t);
        },
        csubtotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].setotal);
            }
            return this.$RoundNum(t);
        },
        extotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].expense);
            }
            return this.$RoundNum(t);
        },
        startdate: function() {
            return this.employees.startdate;
        },
        ptotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].percentage);
            }
            return this.$RoundNum(t);
        },
        itotal: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].total);
            }
            return this.$RoundNum(t);
        },
        ivat: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].vat);
            }
            return this.$RoundNum(t);
        },
        iftotal: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].ftotal);
            }
            return this.$RoundNum(t);
        },
        iexpense: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].expense);
            }
            return this.$RoundNum(t);
        },
        iremain: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].remain);
            }
            return this.$RoundNum(t);
        },
        ipercentage: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].percentage);
            }
            return this.$RoundNum(t);
        },
        netsalary: function() {
            let t= 0;
            t = parseFloat(this.employees.stotal) + parseFloat(this.ipercentage) + parseFloat(this.ptotal);
            t = parseFloat(t) - parseFloat(this.discounts) - parseFloat(this.padvanced) + parseFloat(this.bonuce)
            return this.$RoundNum(t);
        },
        installment_total: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.payadvanced[i].amount);
            }
            return this.$RoundNum(t);
        },
        installment_amount: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.$RoundNum(parseFloat(this.payadvanced[i].amount) / parseFloat(this.payadvanced[i].installment_number)));
            }
            return this.$RoundNum(t);
        },
        installment_paid: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.payadvanced[i].paid_amount);
            }
            return this.$RoundNum(t);
        },
        installment_rest: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.payadvanced[i].rest_amount);
            }
            return this.$RoundNum(t);
        },
        bonuce: function(){
            let t = 0;
            t = this.rewtotals;
            return this.$RoundNum(t);
        },
        discounts: function(){
            let t = 0;
            t = this.dedtotals;
            return this.$RoundNum(t);
        },
        padvanced: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                if(this.payadvanced[i].rest_amount > 0)
                    t = parseFloat(t) + parseFloat(this.$RoundNum(parseFloat(this.payadvanced[i].amount) / parseFloat(this.payadvanced[i].installment_number)));
            }
            return this.$RoundNum(t);
        },
        rewtotals: function(){
            let t = 0;
            for(let i=0;i<this.rewarded.length;i++){
                t = parseFloat(t) + parseFloat(this.rewarded[i].amount);
            }
            return this.$RoundNum(t);
        },
        dedtotals: function(){
            let t = 0;
            for(let i=0;i<this.deducts.length;i++){
                t = parseFloat(t) + parseFloat(this.deducts[i].amount);
            }
            return this.$RoundNum(t);
        },
        custotals: function(){
            let t = 0;
            for(let i=0;i<this.custodies.length;i++){
                t = parseFloat(t) + parseFloat(this.custodies[i].amount);
            }
            return this.$RoundNum(t);
        },
        cusptotals: function(){
            let t = 0;
            for(let i=0;i<this.custodies.length;i++){
                t = parseFloat(t) + parseFloat(this.custodies[i].paid);
            }
            return this.$RoundNum(t);
        },
        cusrtotals: function(){
            let t = 0;
            for(let i=0;i<this.custodies.length;i++){
                t = parseFloat(t) + parseFloat(this.custodies[i].remaining);
            }
            return this.$RoundNum(t);
        },
        btotal: function(){
            return this.employees.stotal;
        },
        endofservices: function() {
            let t = 0;
            const date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            t = this.$EndService(this.$GetTimeStamp(this.startdate), this.$GetTimeStamp(date) , this.btotal)
            return this.$RoundNum(t);
        },
    }
}
</script>